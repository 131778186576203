/* eslint-disable */
export default function() {
    if (typeof ymaps === 'undefined') return
    ymaps.ready(() => {
        const myMap = new ymaps.Map(
            'map',
            {
                center: [55.722659, 37.691701],
                zoom: 17,
                behaviors: ['default', 'scrollZoom'],
                controls: [],
            },
            {
                searchControlProvider: 'yandex#search',
                minZoom: 3,
                maxZoom: 18,
            }
        )

        // Создание макета балуна на основе Twitter Bootstrap.

        const MyBalloonLayout = ymaps.templateLayoutFactory.createClass(
            '<div class="map-balloon">' +
                '<a class="map-balloon__close js-map-balloon-close" href="#">&times;</a>' +
                '$[[options.contentLayout observeSize minWidth=235 maxWidth=235 maxHeight=350]]' +
                '</div>',
            {
                build() {
                    this.constructor.superclass.build.call(this)
                    this.$element = $('.map-balloon', this.getParentElement())
                    this.applyElementOffset()
                    this.$element
                        .find('.js-map-balloon-close')
                        .on('click', $.proxy(this.onCloseClick, this))
                },
                clear() {
                    this.$element.find('.js-map-balloon-close').off('click')

                    this.constructor.superclass.clear.call(this)
                },
                onSublayoutSizeChange() {
                    MyBalloonLayout.superclass.onSublayoutSizeChange.apply(
                        this,
                        arguments
                    )
                    if (!this._isElement(this.$element)) {
                        return
                    }
                    this.applyElementOffset()
                    this.events.fire('shapechange')
                },
                applyElementOffset() {
                    this.$element.css({
                        left: -(this.$element[0].offsetWidth / 2),
                        top: -(this.$element[0].offsetHeight + 25),
                    })
                },
                onCloseClick(e) {
                    e.preventDefault()
                    this.events.fire('userclose')
                },
                // getShape: function() {
                //     if (!this._isElement(this.$element)) {
                //         return MyBalloonLayout.superclass.getShape.call(this);
                //     }
                //     var position = this.$element.position();
                //     return new ymaps.shape.Rectangle(new ymaps.geometry.pixel.Rectangle([
                //         [position.left, position.top],
                //         [
                //             position.left + this.$element[0].offsetWidth,
                //             position.top + this.$element[0].offsetHeight
                //         ]
                //     ]));
                // },
                _isElement(element) {
                    return element && element[0]
                },
            }
        )

        // Создаем собственный макет с информацией о выбранном геообъекте.

        const customClusterBalloonLayout = ymaps.templateLayoutFactory.createClass(
            '<div class="map-balloon">' +
                '<a class="map-balloon__close js-map-balloon-close" href="#">&times;</a>' +
                '<div class="map-balloon-list js-scroll-map">' +
                '<div class="map-balloon-list__inner">' +
                // Выводим в цикле список всех геообъектов.
                '{% for geoObject in properties.geoObjects %}' +
                '<div class=map-balloon-list__item>{{ geoObject.properties.balloonContent|raw }}</div>' +
                '{% endfor %}' +
                '</div>' +
                '</div>' +
                '</div>',
            {
                build() {
                    this.constructor.superclass.build.call(this)
                    this.$element = $('.map-balloon', this.getParentElement())
                    this.applyElementOffset()
                    this.$element
                        .find('.js-map-balloon-close')
                        .on('click', $.proxy(this.onCloseClick, this))
                    setTimeout(() => {
                        $('.js-scroll-map').niceScroll()
                    }, 400)
                },
                clear() {
                    this.$element.find('.js-map-balloon-close').off('click')

                    this.constructor.superclass.clear.call(this)
                },
                onSublayoutSizeChange() {
                    MyBalloonLayout.superclass.onSublayoutSizeChange.apply(
                        this,
                        arguments
                    )
                    if (!this._isElement(this.$element)) {
                        return
                    }
                    this.applyElementOffset()
                    this.events.fire('shapechange')
                },
                applyElementOffset() {
                    this.$element.css({
                        left: -(this.$element[0].offsetWidth / 2),
                        top: -(this.$element[0].offsetHeight + 30),
                    })
                },
                onCloseClick(e) {
                    e.preventDefault()
                    this.events.fire('userclose')
                },
                getShape() {
                    if (!this._isElement(this.$element)) {
                        return MyBalloonLayout.superclass.getShape.call(this)
                    }
                    const left = -(this.$element[0].offsetWidth / 2)
                    const top = -(this.$element[0].offsetHeight + 25)

                    return new ymaps.shape.Rectangle(
                        new ymaps.geometry.pixel.Rectangle([
                            [left, top],
                            [
                                left + this.$element[0].offsetWidth,
                                top + this.$element[0].offsetHeight,
                            ],
                        ])
                    )
                },
                _isElement(element) {
                    return element && element[0]
                },
            }
        )

        const getPointData = function(el) {
            const address = el.find('.address-list__address').html()

            const time = el.find('.address-list__time').html()

            let phone = ''

            let email = ''

            $(el.find('.address-list__phone')).each(function() {
                phone += `<div class="map-balloon__phone">${$(
                    this
                ).html()}</div>`
            })
            $(el.find('.address-list__email')).each(function() {
                email += `<div class="map-balloon__email">${$(
                    this
                ).html()}</div>`
            })
            return {
                balloonContent:
                    `${'<div class="map-balloon__inner">' +
                        '<div class="map-balloon__title">'}${el
                        .find('.address-list__title')
                        .html()}</div>` +
                    `<div class="map-balloon__address">${address}</div>` +
                    `<div class="map-balloon__time">${time}</div>${phone}${email}</div>`,
            }
        }

        const clusterer = new ymaps.Clusterer({
            clusterDisableClickZoom: true,
            clusterOpenBalloonOnClick: true,
            // Устанавливаем режим открытия балуна.
            // В данном примере балун никогда не будет открываться в режиме панели.
            clusterBalloonPanelMaxMapArea: 0,
            // По умолчанию опции балуна balloonMaxWidth и balloonMaxHeight не установлены для кластеризатора,
            // так как все стандартные макеты имеют определенные размеры.
            clusterBalloonMaxHeight: 200,
            gridSize: 200,
            // Устанавливаем собственный макет контента балуна.
            clusterBalloonLayout: customClusterBalloonLayout,
            hideIconOnBalloonOpen: false,
            preset: 'islands#invertedNightClusterIcons',
        })

        const geoObjects = []
        $('.address-list__item').each((index, value) => {
            let coordinate = []
            const el = $(value)
            coordinate = $(value)
                .attr('data-coordinate')
                .split(',')
            for (let i = 0; i < coordinate.length; i++) {
                coordinate[i] = +coordinate[i]
            }

            // if (index == 1) {
            // console.log(el);
            const placemark = new ymaps.Placemark(
                coordinate,
                getPointData(el),
                {
                    balloonShadow: false,
                    balloonLayout: MyBalloonLayout,
                    balloonPanelMaxMapArea: 0,

                    // iconLayout: 'default#image',
                    // iconImageHref: 'images/sprite-images/pin-1.png',
                    // iconImageSize: [39, 50],
                    // iconImageOffset: [-20, -50],
                    // Не скрываем иконку при открытом балуне.
                    // hideIconOnBalloonOpen: false,
                    // И дополнительно смещаем балун, для открытия над иконкой.
                    // balloonOffset: [0, 0],
                    balloonPanelMaxMapArea: 0,
                    hideIconOnBalloonOpen: false,
                    preset: 'islands#nightCircleDotIconWithCaption',
                }
            )
            geoObjects.push(placemark)
            // }
        })

        clusterer.add(geoObjects)
        myMap.geoObjects.add(clusterer)
        myMap.controls.add('zoomControl', {
            size: 'small',
            position: {
                bottom: 40,
                left: 10,
            },
        })
        myMap.setBounds(myMap.geoObjects.getBounds(), {
            // place current geoobjects on visible area
            checkZoomRange: true,
        })

        myMap.behaviors.disable('scrollZoom')

        $(document).on('click', '.js-address-list-item', function() {
            const link = $(this)
            const coords = link.attr('data-coordinate').split(',')
            const lat = Number(coords[0])
            const long = Number(coords[1])

            link.addClass('active')
                .siblings()
                .removeClass('active')

            $('html, body').animate(
                {
                    scrollTop: $('#map').offset().top - 10,
                },
                300
            )
            myMap
                .panTo([lat, long], {
                    checkZoomRange: false,
                    duration: 1000,
                    // delay: 1000
                })
                .then(() => {
                    myMap.balloon.close()
                    myMap.setZoom(18)
                    // forEach(function(item, i, arr) {
                    geoObjects.forEach((placemark, context) => {
                        if (
                            placemark.geometry.getCoordinates()[0] == lat &&
                            placemark.geometry.getCoordinates()[1] == long
                        ) {
                            setTimeout(() => {
                                placemark.balloon.open()
                            }, 500)
                        }
                    })
                })
        })
    })
}
/* eslint-enable */
