/* eslint-disable */
export function debouncedresize() {
    const $event = $.event

    let $special

    let resizeTimeout
    $special = $event.special.debouncedresize = {
        setup() {
            $(this).on('resize', $special.handler)
        },
        teardown() {
            $(this).off('resize', $special.handler)
        },
        handler(event, execAsap) {
            // Save the context
            const context = this

            const args = arguments

            const dispatch = function() {
                // set correct event type
                event.type = 'debouncedresize'
                $event.dispatch.apply(context, args)
            }
            if (resizeTimeout) {
                clearTimeout(resizeTimeout)
            }
            execAsap
                ? dispatch()
                : (resizeTimeout = setTimeout(dispatch, $special.threshold))
        },
        threshold: 150,
    }
    return $special
}
/* eslint-enable */

export function changeImg() {
    if (window.innerWidth < 767) {
        $('.js-main-slider-img').attr('src', function() {
            return $(this).attr('data-mobile-src')
        })
    }
}

export function moveBlock() {
    $('[data-pos="tablet"]').each(function() {
        const id = $(this).attr('data-move')
        if (window.innerWidth < 1024) {
            $(`[data-move="${id}"][data-pos="tablet"]`)
                .children()
                .appendTo($(`[data-move="${id}"][data-pos="mobile"]`))
        } else {
            $(`[data-move="${id}"][data-pos="mobile"]`)
                .children()
                .appendTo($(`[data-move="${id}"][data-pos="tablet"]`))
        }
    })
}

export function mobileBrowser() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    )
} 
