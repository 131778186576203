export default function() {
    $('.js-filter-list-more').on('click', function() {
        const el = $(this)
        const parent = el.parents('.b-filter-list')
        const dropdown = parent.find('.b-filter-list__dropdown')
        $('.b-filter-list').removeClass('active')
        parent.addClass('active')
        dropdown.find('.b-filter-list__dropdown-items').html(
            parent
                .find('.b-filter-list__body')
                .children()
                .clone()
        )

        $(document).off('click', '.b-filter-list__item') // TODO fix
        dropdown.on('click', '.b-filter-list__item', function() {
            $(this)
                .find('input')
                .toggleClass('active')
        })
        $('body').addClass('is-filter-list-drop-show')
        $('.js-filter-list-close').on('click', () => {
            $('body').removeClass('is-filter-list-drop-show')
            parent.removeClass('active')
        })

        setTimeout(() => {
            dropdown.find('.js-scroll-filter').niceScroll({
                horizrailenabled: false,
                autohidemode: false,
                cursorcolor: '#13729e',
                cursorborder: false,
                cursorwidth: 4,
                background: '#e1e1e1',
                railoffset: { top: 10 },
            })
        }, 500)
    })
}
