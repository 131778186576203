export default function() {
    const mainSlider = $('.js-main-slider')
    mainSlider.slick({
        speed: 1000,
        autoplay: true,
        adaptiveHeight: true,
        autoplaySpeed: 5000,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        dots: true,
        zIndex: 1,
        prevArrow: '.js-main-slider-prev',
        nextArrow: '.js-main-slider-next',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    fade: false,
                },
            },
        ],
        rows: 0,
    })
}
