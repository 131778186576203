import * as constants from 'constants.js'

export default function() {
    const sliderBrands = $('.js-brands-slider')
    sliderBrands.slick({
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 5000,
        infinite: true,
        slidesToShow: 7,
        slidesToScroll: 7,
        rows: 1,
        slidesPerRow: 1,
        prevArrow: '.js-brands-slider-prev',
        nextArrow: '.js-brands-slider-next',
        // mobileFirst: true,
        responsive: [
            {
                breakpoint: constants.screenLgMax,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                },
            },
            {
                breakpoint: constants.screenMdMax,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: constants.screenSmMax,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: constants.screenXsMax,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    rows: 3,
                    slidesPerRow: 2,
                    dots: true,
                },
            },
        ],
    })
}
