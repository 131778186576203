import sliderProductModal from 'sliders/productModal/sliderProductModal'

export default function() {
	$('#modal-product').on('shown.bs.modal', () => {
		sliderProductModal()
	})

	$('[data-target="#modal-sert"]').on('click', function() {
		const src = $(this).attr('data-src')
		$('#modal-sert')
			.find('.modal-body')
			.empty()
			.html(`<img width="1000" src="${src}" alt=""><br><br>`)
	})

	$('.modal').on('hidden.bs.modal', () => {
		$('.form-group')
			.removeClass('has-error has-success')
			.find('.help-block')
			.css('display', 'none')
	})
}
