import mainSlider from 'sliders/main/slider-main'
import sliderBrands from 'sliders/brands/slider-brands'
import sliderProduct from 'sliders/product/sliderProduct'
import sliderShowroom from 'sliders/showroom/sliderShowroom'
import productRelated from 'product/related/product-related'
import { debouncedresize, moveBlock, changeImg, mobileBrowser } from './helpers'
import filterSide from './components/filterSide'
import forms from './components/forms'
import modals from './components/modals'
import map from './components/map'

debouncedresize()
map()
mainSlider()
sliderBrands()
sliderProduct()
sliderShowroom()

changeImg()
moveBlock()

filterSide()

productRelated()
forms()
modals()

let searchTimeout

if (mobileBrowser()) $('body').addClass('mobileBrowser')

$(window).on('debouncedresize', () => {
    changeImg()
    moveBlock()
})

function sizeToggleContent() {
    $(document)
        .find('.toggle-list__content:not(.done)')
        .each(function() {
            const el = $(this)
            el.css('height', el.outerHeight())
            el.addClass('done')
        })
}
sizeToggleContent()

// Mobile Sidebar
$('.js-mobile-sidebar-link').on('click', function(e) {
    const parent = $(this).parent()
    if ($('.nav-catalog__title').is(':hidden')) {
        $('body').addClass('is-mobile-sidebar-show is-overlay-show')
        if (parent.hasClass('active')) {
            parent.removeClass('active')
        } else {
            parent.addClass('active')
        }
        e.preventDefault()
    }
})

$('.js-hide-overlay').on('click', e => {
    $('body').removeClass(
        'is-mobile-sidebar-show is-search-show is-search-show-active is-overlay-show is-filter-list-drop-show is-favs-filter-open'
    )
    $(
        '.js-nav-cat-list-item, .js-nav-cat-list-back, .b-filter-list, .js-address-info-toggle, .nav-catalog, .b-nav-user'
    ).removeClass('active')
    clearTimeout(searchTimeout)
    e.preventDefault()
})

$('.js-nav-cat-list-item').on('click', function(e) {
    const el = $(this)
    if (!$(e.target).is('a')) {
        if (el.hasClass('active')) {
            el.removeClass('active')
        } else {
            el.addClass('active')
            $('.js-nav-cat-list-back').addClass('active')
        }
    }
})
$('.js-nav-footer-toogle').on('click', function() {
    const el = $(this).parent()
    if (el.hasClass('active')) {
        el.removeClass('active')
    } else {
        el.addClass('active')
    }
})

$(window).scroll(() => {
    const scrollTop = $(window).scrollTop()
    if (scrollTop > 300) {
        $('.js-go-top').addClass('active')
    } else {
        $('.js-go-top').removeClass('active')
    }
})
$('.js-go-top').on('click', () => {
    $('html, body').animate(
        {
            scrollTop: 0,
        },
        800
    )
})

$('.js-edit-link').on('click', function(e) {
    const el = $(this)
    const parent = el.parents('.form')
    if (parent.hasClass('active')) {
        parent.removeClass('active')
        parent.find('.form-control').attr('disabled', true)
    } else {
        parent.addClass('active')
        parent.find('.form-control').removeAttr('disabled')
    }
    e.preventDefault()
})

$('.js-nav-cat-list-back').on('click', () => {
    $('.js-nav-cat-list-item, .js-nav-cat-list-back').removeClass('active')
})

/* eslint-disable */
const clearText = function(el) {
    var clearTextSymbol = setInterval(() => {
        if (el.val() == '') {
            clearInterval(clearTextSymbol)
            el.focus()
        }
        el.val(el.val().substr(1))
    }, 20)
}
/* eslint-enable */

$(document).on('click', '.js-address-info-toggle', function() {
    const el = $(this)
    if (el.hasClass('active')) {
        el.removeClass('active')
        $('body').removeClass('is-overlay-show')
    } else {
        el.addClass('active')
        $('body').addClass('is-overlay-show')
    }
})

$('.js-basket-item-delete').on('click', function() {
    const parent = $(this).parents('.basket-list__item')
    parent.addClass('basket-list__item_hide')
    setTimeout(() => {
        parent.remove()
    }, 500)
})

$(document).on('click', '.js-toggle-link', function() {
    const parent = $(this).parents('.toggle-list__item')
    if (parent.hasClass('active')) {
        parent.removeClass('active')
    } else {
        parent.siblings().removeClass('active')
        parent.addClass('active')
    }
})

$('.js-favs-filter-link').on('click', () => {
    $('body').addClass('is-favs-filter-open')
})
$('.js-favs-filter-close').on('click', () => {
    $('body').removeClass('is-favs-filter-open')
})
