import { mobileBrowser } from '../helpers'

function selects() {
    if (mobileBrowser() === false) {
        $('.js-select2:not([multiple])').select2({
            width: '100%',
            minimumResultsForSearch: 30,
            placeholder: $(this).data('placeholder'),
        })
        $('.js-select2[multiple]').select2({
            width: '100%',
            minimumResultsForSearch: Infinity,
            placeholder: $(this).data('placeholder'),
            allowClear: true,
            closeOnSelect: false,
        })
    }
    $('.js-select2').on('select2:open', () => {
        $('.select2-results__options').niceScroll({
            autohidemode: false,
            cursorcolor: '#13729e',
            cursorborder: false,
            cursorwidth: 4,
            background: '#e1e1e1',
            railoffset: { left: -10 },
        })
    })
    $('.js-select2[multiple]').on('change', function() {
        const el = $(this)
        function isPositive(number) {
            return number === 'all'
        }
        if (el.val().length) {
            el.addClass('selected')
        } else {
            el.removeClass('selected')
        }
        if (el.hasClass('is-set-all') && el.val().length > 1) {
            el.removeClass('is-set-all')
            const arr = el.val()
            arr.shift()
            el.val(arr)
        } else if (el.val().some(isPositive)) {
            el.addClass('is-set-all')
            el.val(['all'])
        }
        el.trigger('change.select2')
    })
    $('.js-select-wrapper select').on('change', function() {
        const el = $(this)
        let text = ''
        el.find('option:selected').each(function(index) {
            if (index === 0) {
                text += $(this).text()
            } else {
                text += `, ${$(this).text()}`
            }
        })
        el.parent().attr('data-mobile-placeholder', text)
    })

    $('.js-select-wrapper select').on('click', function() {
        if (mobileBrowser()) {
            $(this)
                .parent()
                .addClass('is-focused')
        }
    })
}

function setGroupFocus() {
    $(document)
        .find('.has-group-focus .form-control')
        .focusin(function() {
            $(this)
                .parents('.form-group')
                .addClass('is-focused')
        })
    $(document)
        .find('.has-group-focus .form-control')
        .focusout(function() {
            $(this)
                .parents('.form-group')
                .removeClass('is-focused')
        })
}

export default function() {
    selects()
    setGroupFocus()
    $('.js-show-pass').on('click', function() {
        const el = $(this)

        const control = el.parents('.form-group').find('.form-control')
        if (el.hasClass('active')) {
            el.removeClass('active')
            control[0].type = 'password'
        } else {
            el.addClass('active')
            control[0].type = 'text'
        }
    })

    $('.js-counter-number').on('keyup', function() {
        this.value = this.value.replace(/[^0-9.]/g, '')
        if ($(this).val() > 1000) $(this).val(1000)
    })

    $('.js-counter-number').on('focusin', function() {
        if (mobileBrowser()) {
            $(this).val('')
        } else {
            $(this).select()
        }
    })

    $('.js-counter-number').on('focusout', function() {
        const el = $(this)
        if (el.val() === '' || el.val() < 1) el.val(1)
    })

    $('.js-counter-data').each(function() {
        const el = $(this)
        el.parent().attr('data-counter', el.val())
    })
    $('.js-counter-data').on('change', function() {
        const el = $(this)
        el.parent().attr('data-counter', el.val())
    })

    $('.js-counter-control').on('click', function() {
        $(this)
            .children('input')
            .focus()
            .select()
    })

    $('.js-counter-control input').on('focusin', function() {
        $(this)
            .parents('.js-counter1')
            .addClass('active')
    })
    $('.js-counter-control input').on('focusout', function() {
        $(this)
            .parents('.js-counter1')
            .removeClass('active')
    })
}
