export default function() {
	const productLgSlider = '.js-product-lg-gallery-slider'
	const productLgSliderThumbs = '.js-product-lg-gallery-thumbs'
	$(productLgSlider).slick({
		infinite: false,
		slidesToShow: 1,
		dots: false,
		slidesToScroll: 1,
		prevArrow: $('.js-product-lg-gallery-slider-prev'),
		nextArrow: $('.js-product-lg-gallery-slider-next'),
		asNavFor: $(productLgSliderThumbs),
		rows: 0,
	})
	$(productLgSliderThumbs).slick({
		lazyLoad: 'ondemand',
		vertical: true,
		infinite: false,
		slidesToShow: 4,
		dots: false,
		arrows: false,
		slidesToScroll: 1,
		rows: 0,
	})
	$(productLgSliderThumbs)
		.find('.slick-slide')
		.on('click', function() {
			$(productLgSliderThumbs)
				.find('.slick-slide')
				.removeClass('slick-current')
			$(this).addClass('slick-current')
			$(productLgSlider).slick('slickGoTo', $(this).index())
		})
}
