export default function() {
	const productLgModalSlider = '.js-product-lg-gallery-modal-slider'
	const productLgModalSliderThumbs = '.js-product-lg-gallery-modal-thumbs'
	$(productLgModalSlider).slick({
		infinite: false,
		slidesToShow: 1,
		dots: false,
		slidesToScroll: 1,
		prevArrow: $('.js-product-lg-gallery-modal-slider-prev'),
		nextArrow: $('.js-product-lg-gallery-modal-slider-next'),
		// fade: true,
		asNavFor: $(productLgModalSliderThumbs),
		rows: 0,
	})
	$(productLgModalSliderThumbs).slick({
		lazyLoad: 'ondemand',
		vertical: true,
		infinite: false,
		slidesToShow: 4,
		dots: false,
		arrows: false,
		slidesToScroll: 1,
		rows: 0,
	})
	$(productLgModalSliderThumbs)
		.find('.slick-slide')
		.on('click', function() {
			$(productLgModalSliderThumbs)
				.find('.slick-slide')
				.removeClass('slick-current')
			$(this).addClass('slick-current')
			$(productLgModalSlider).slick('slickGoTo', $(this).index())
		})
}
