import * as constants from 'constants.js'

export default function() {
	const showroomSlider = $('.js-showroom-slider')
	showroomSlider.slick({
		speed: 1000,
		autoplay: true,
		autoplaySpeed: 5000,
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 3,
		rows: 0,
		// slidesPerRow: 1,
		prevArrow: '.js-showroom-slider-prev',
		nextArrow: '.js-showroom-slider-next',
		// mobileFirst: true,
		responsive: [
			{
				breakpoint: constants.screenLgMax,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
				},
			},
			{
				breakpoint: constants.screenMdMax,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				},
			},
			{
				breakpoint: constants.screenSmMax,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				},
			},
			{
				breakpoint: constants.screenXsMax,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					// rows: 3,
					// slidesPerRow: 3,
					dots: true,
				},
			},
		],
	})
}
