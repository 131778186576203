import filterMore from 'filter-side/filter-side'

export default function() {
	filterMore()

	const elements = document.querySelectorAll('.js-filter-slider-control')
	Array.prototype.forEach.call(elements, el => {
		const parent = $(el).parents('.js-filter-slider')
		const lower = parent.find('.js-filter-slider-lower')
		const upper = parent.find('.js-filter-slider-upper')
		noUiSlider.create(el, {
			start: [lower.attr('data-start'), upper.attr('data-start')],
			connect: true,
			range: {
				min: 0,
				// '50%': 2500,
				max: 1000,
			},
			// tooltips: true,
			// step: 10,
			// limit: 200,
			pips: {
				// Show a scale with the slider
				mode: 'steps',
				stepped: true,
				density: 4,
			},
		})
		el.noUiSlider.on('update', () => {
			lower.val(parseFloat(el.noUiSlider.get()[0]).toFixed(0))
			upper.val(parseFloat(el.noUiSlider.get()[1]).toFixed(0))
		})
	})

	$('.js-filter-slider-lower').on('change', function() {
		const el = $(this)
		const slider = el
			.parents('.js-filter-slider')
			.find('.js-filter-slider-control')
		slider[0].noUiSlider.set([this.value, null])
	})
	$('.js-filter-slider-upper').on('change', function() {
		const el = $(this)
		const slider = el
			.parents('.js-filter-slider')
			.find('.js-filter-slider-control')
		slider[0].noUiSlider.set([null, this.value])
	})
	$(document).on('click', event => {
		if (!$(event.target).closest('.b-filter-list.active').length) {
			$('.b-filter-list').removeClass('active')
		}
	})
}
